import { UiLayout } from '@/components/ui/ui-layout'
import { Suspense, lazy } from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'

// Lazy load components with explicit chunk names
const AccountListFeature = lazy(() => import(/* webpackChunkName: "account-list" */ '../components/account/account-list-feature'))
const AccountDetailFeature = lazy(() => import(/* webpackChunkName: "account-detail" */ '../components/account/account-detail-feature'))
const ClusterFeature = lazy(() => import(/* webpackChunkName: "cluster" */ '../components/cluster/cluster-feature'))
const IdoDemoWebFeature = lazy(() => import(/* webpackChunkName: "ido-demo" */ '../components/ido_demo_web/ido_demo_web-feature'))
const DashboardFeature = lazy(() => import(/* webpackChunkName: "dashboard" */ '../components/dashboard/dashboard-feature'))

const links: { label: string; path: string }[] = [
  { label: 'Account', path: '/account' },
  { label: 'Clusters', path: '/clusters' },
  { label: 'IdoDemoWeb Program', path: '/ido_demo_web' },
]

const routes: RouteObject[] = [
  { path: '/account/', element: <Suspense fallback={<div>Loading...</div>}><AccountListFeature /></Suspense> },
  { path: '/account/:address', element: <Suspense fallback={<div>Loading...</div>}><AccountDetailFeature /></Suspense> },
  { path: '/ido_demo_web', element: <Suspense fallback={<div>Loading...</div>}><IdoDemoWebFeature /></Suspense> },
  { path: '/clusters', element: <Suspense fallback={<div>Loading...</div>}><ClusterFeature /></Suspense> },
]

export function AppRoutes() {
  const router = useRoutes([
    { index: true, element: <Navigate to={'/dashboard'} replace={true} /> },
    { 
      path: '/dashboard', 
      element: <Suspense fallback={<div>Loading...</div>}><DashboardFeature /></Suspense> 
    },
    ...routes,
    { path: '*', element: <Navigate to={'/dashboard'} replace={true} /> },
  ])
  return <UiLayout links={links}>{router}</UiLayout>
}
